import * as React from "react"
import Layout from "../components/layout";
import {Link} from "gatsby";
import Social from "../components/social";

const IndexPage = () => {
  return (
      <Layout isHomepage title="Home">
        <div className="h-screen/4 xs:h-screen/2">&nbsp;</div>
        <div className="text-center">
          <h1 className="mb-6 text-7xl">Jendrik's World</h1>
          <Social/>
          <p className="my-6 text-2xl">Coming soon.</p>
          <div className="flex flex-row justify-center">
            <Link to="/contact">
              <button className="mr-2">Contact</button>
            </Link>
            <Link to="/about">
              <button>About</button>
            </Link>
          </div>
          {/*<div className="spotify-follow d-flex flex-row justify-content-center">*/}
          {/*  <iframe*/}
          {/*    src="https://open.spotify.com/follow/1/?uri=spotify:artist:58E6TLHwF0cHdkrLd5iNRh?si=wIMBSlyASfOifi94GOTIQA&size=detail&theme=dark"*/}
          {/*    width="300" height="56" scrolling="no" frameBorder="0" style={{border: 'none', overflow: 'hidden'}}*/}
          {/*    allowTransparency="true">*/}
          {/*  </iframe>*/}
          {/*</div>*/}
        </div>
      </Layout>
  )
}

export default IndexPage
